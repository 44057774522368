import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';

/* Configure Amplify resources */
Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-central-1_AXdLXciZX',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '5tvsnc06s782ohgug3nk2s41d9'
  }
});


@NgModule({
  declarations: [AppComponent],
  imports: [AmplifyUIAngularModule /* Add Amplify module */, BrowserModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}